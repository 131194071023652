


































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { Actions, store } from '../store';
import DialogContent from '@/components/DialogContent.vue';
import { Route, RawLocation } from 'vue-router';

@Component<ResetPassword>({
  beforeRouteEnter(to, from, next): void
  {
    next(vm => vm.handleBeforeRouteEnter(to, from, next));
  },
  created()
  {
    store[Actions.LOGOUT]();
  },
  activated()
  {
    store[Actions.LOGOUT]();
  },
  components: {
    DialogContent,
  },
})
export default class ResetPassword extends Vue
{
  /**
   * The form data.
   */
  public readonly formData = {
    password: '',
    passwordConfirm: '',
  };

  /**
   * Whether to display the password.
   */
  public showPassword = false;

  private handleBeforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false) => void)
  {
    if (to.path && to.path.startsWith('/resetpassword'))
    {
      this.title = this.$t('resetPassword.title') as string;
    }
    else if (to.path && to.path.startsWith('/activation'))
    {
      this.title = this.$t('resetPassword.titleActivation') as string;
    }
  }

  private title = '';

  /**
   * Property to disable the form while submitting.
   */
  private isSubmitting = false;

  public submit(): void
  {
    this.validatePassword()
      .then(valid =>
      {
        if (valid)
        {
          this.isSubmitting = true;

          return store[Actions.RESET_PASSWORD]({
            passwordRequestUid: this.$route.params.rid,
            passwordNew: this.formData.password,
          })
            .then(() =>
            {
              this.$router.replace({
                path: '/',
                query: {
                  ref: 'reset',
                },
                replace: true,
              });
            });

        }
      })
      .finally(() => this.isSubmitting = false);
  }

  /**
   * Validates the password fields.
   */
  private validatePassword(): Promise<boolean>
  {
    return (this.$refs.validator as InstanceType<typeof ValidationObserver>).validate();
  }
}
